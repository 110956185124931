import { Component, OnInit, AfterViewInit } from '@angular/core';
import Swiper from 'swiper';

@Component({
  selector: 'app-bases',
  templateUrl: './une.component.html',
  styleUrls: ['./une.component.scss']
})
export class UneComponent implements OnInit, AfterViewInit {
  mySwiper: Swiper;
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.mySwiper = new Swiper('.swiper-container');
  }
}
