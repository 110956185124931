import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import swal from 'sweetalert2'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CorreoResponse, RegistroService } from '../core/services/registro/registro.service';
import * as myGlobals from '../cotizador/cotizador.component';
import { CrearPDFService } from '../core/services/crearPDFservice/crear-pdf.service.service';
import { Router } from '@angular/router';
//import { Console } from 'console';
//import * as html2pdf from '../crearPDF';
//import $ from "jquery";



//Variables Formulario
var InputNombreLocal, InputTelefonoLocal, InputCorreoLocal, ComboEstadoLocal, ComboMarcaLocal, InputModeloLocal, ComboAnioLocal = '';
var Periodo, PeriodoString = '';
//Variables Tabla 1
var InputMontoLocal, InputEngancheLocal, InputSaldoFinanciadoLocal, ComboPlazoLocal, MensualidadLocal, FechaDeCalculo = '';
var NoPagosAlAnio = 0;
var NoDeAnioss = 0;
var NoPagosEnTotal = 0;
//Variables Tabla2

var Intereses = 0;
var MensualidadT2, ComisionPorApertura = 0;
var MensualidadCAT = 0;
var SumatoriaIntereses = 0;
var SumatoriaCapital = 0;
var TotalInteresesMasCapital = 0;
var TasaAnual = 0;


//variables que van al correo
var InputCPLocal, ComboTipoAutoLocal, ComboCondicionAutoLocal, AnioAutoLocal, InputMontoLocalFormateado, InputEngancheLocalFormateado, InputSaldoFinanciadoLocalFormateado, ComboPlazoLocalAux = '';

@Component({
  selector: 'app-crearPDF',
  templateUrl: './crearPDF.component.html',
  styleUrls: ['./crearPDF.component.css']
})


export class CrearPDFComponent implements AfterViewInit, OnInit {
  //formulario
  formularioContacto: FormGroup;
  NombreCliente: string;
  //variables
  ValorAuto: number = 0;
  Enganche: number = 0;
  EngancheMinimo: number = 0;
  MontoAPrestar: number = 0;
  EngancheValido: boolean = false;
  MontoAPrestarValido: boolean = false;
  EstadoSel: string;
  TipoAuto: string;
  CondicionAuto: string;
  AnioAuto: string;
  MarcaAuto: string;
  PlazoPago: string;
  Periodoo: string;
  PeriodoNuum: number;
  ModeloAuto: string;
  html2pdf: any;
  SaldoRestante: number = 0;
  NoPagosAlAnioMes = 12;
  NoPagosAlAnioQuincenas = 26;
  NoPagosAlAnioSemanas = 52;
  TasaInteresAnualAuto = 0.16;
  TasaInteresAnualMoto = 0.45;


  //VALORES QUE VIENEN DEL OTRO FORM


  constructor(
    private formBuilder: FormBuilder,
    protected alertService: AlertService,
    private SpinnerService: NgxSpinnerService,
    private insR: RegistroService,
    private CrearPDFServ: CrearPDFService,
    @Inject('BASE_URL') public baseUrl: string,
    @Inject(HttpClient) private http: HttpClient,
    private router: Router
  ) {
    this.buildForm();

  }

  ngAfterViewInit() {
    //this.CrearPDFServ.disparadorDeFavoritos.subscribe(data => {
    //  console.log("recibiendo data after :", data['data']['InputNombre']);
    //  const entries = Object.values(data);

    //  //data
    //  NombreCliente2 = data['data']['InputNombre'];
    //  alert("data vale: " + data['data']['InputNombre']);
    //  alert("NombreCliente2 vale: " + NombreCliente2);

    //  //this.setearValores(entries);
    //})

  }


  //1.- AQUI SOLO SE OBTIENEN LOS DATOS Y SE GUARDAN EN VARIABLES LOCALES PARA DESPUES SER MOSTRADOS EN LOS ELEMENTOS EN CargarDatos()
  setearValores(newObject) {
    InputNombreLocal = newObject["InputNombre"];
    InputTelefonoLocal = newObject["InputTelefono"];
    InputCorreoLocal = newObject["InputCorreo"];
    ComboEstadoLocal = newObject["ComboEstado"];
    ComboMarcaLocal = newObject["ComboMarca"];
    InputModeloLocal = newObject["InputModelo"];
    ComboAnioLocal = newObject["ComboAnio"];
    InputMontoLocal = newObject["InputMonto"];
    InputEngancheLocal = newObject["InputEnganche"];
    InputSaldoFinanciadoLocal = newObject["InputSaldoFinanciado"];
    ComboTipoAutoLocal = newObject["ComboTipoAuto"];
    NoPagosAlAnio = newObject["NoPagosAlAnio"];
    NoDeAnioss = newObject["NoDeAnios"];
    console.log("NoDeAnioss", NoDeAnioss);

    this.formularioContacto.patchValue({
      InputNombre: newObject["InputNombre"],
      InputTelefono: newObject["InputTelefono"],
      InputCorreo: newObject["InputCorreo"],
      ComboEstado: newObject["ComboEstado"],
      ComboMarca: newObject["ComboMarca"],
      InputModelo: newObject["InputModelo"],
      ComboAnio: newObject["ComboAnio"],
      InputMonto: newObject["InputMonto"],
      InputEnganche: newObject["InputEnganche"],
      InputSaldoFinanciado: newObject["InputSaldoFinanciado"],
      ComboTipoAuto: newObject["ComboTipoAuto"],
      NoPagosAlAnio: newObject["NoPagosAlAnio"],
      NoDeAnios: newObject["NoDeAnios"],
      ComboPlazo: newObject["ComboPlazo"],
      ComboPlazoPeriodo: newObject["ComboPlazoPeriodo"]
    });

    //Periodo tambien se usa como multiplicador de
    Periodo = newObject["ComboPlazoPeriodo"];
    console.log("primer periodo 2", Periodo);
    switch (Periodo) {
      case "1": PeriodoString = "MESES"; break;
      case "2": PeriodoString = "QUINCENAS"; break;
      case "3": PeriodoString = "SEMANAS"; break;
    }

    //Validacion para volver a calcular el SaldoFinanciado, en caso de que se pierda el valor en el submit...
    if (!InputSaldoFinanciadoLocal) {
      InputSaldoFinanciadoLocal = InputMontoLocal - InputEngancheLocal;
    }

    //Este es el que viene del combo Plazo (Meses) Lo conertimos a años y lo multiplicamos por el NoPagosAlAnio
    ComboPlazoLocal = newObject["ComboPlazo"] / 12;
    ComboPlazoLocal = ComboPlazoLocal * NoPagosAlAnio;
    ComboPlazoLocalAux = newObject["ComboPlazo"] + PeriodoString;
    InputCPLocal = newObject["ComboPlazo"];
    AnioAutoLocal = newObject["ComboAnio"];
    //por hacer: tipo auto por condicion auto
    var TipoAutoAux = newObject["ComboTipoAuto"];
    ComboTipoAutoLocal = newObject["ComboTipoAuto"];
    //if (TipoAutoAux != "auto") {
    //  TipoAutoAux = "moto";
    //}

    //Condicion auto
    var CondicionAutoAux = newObject["ComboCondicionAuto"];
    if (CondicionAutoAux == "N") {
      CondicionAutoAux = "Nuevo"
    }
    else {
      CondicionAutoAux = "Semi nuevo"
    }

    ComboCondicionAutoLocal = CondicionAutoAux;
    //ComboTipoAutoLocal = TipoAutoAux;

    ComisionPorApertura = InputSaldoFinanciadoLocal * 0.03;
    ComisionPorApertura = ComisionPorApertura + (ComisionPorApertura * 0.16);
    console.log("ComisionPorApertura 2 : " + ComisionPorApertura);
    //var cat = this.GetCAT(InputSaldoFinanciadoLocal, ComisionPorApertura, MensualidadLocal, ComboPlazoLocal, 12);
    //console.log("el CAT es: ", cat);
    //alert(InputSaldoFinanciadoLocal);
    const Valores = {
      SaldoFinanciado: InputSaldoFinanciadoLocal,
      Comision: ComisionPorApertura
    }
    //var data = {
    //  montoAPrestar: InputSaldoFinanciadoLocal,
    //  Comision: ComisionPorApertura
    //};


    /*console.log(x);*/


  }
  //Intento AJAX
  CalcularCAT() {
    console.log("entró a calcular CAT");
    console.log("MensualidadT2", MensualidadT2);
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    });

    this.insR.CalcularCAT(InputSaldoFinanciadoLocal.toString(), ComisionPorApertura.toString(), ComboPlazoLocal, MensualidadT2.toString()).subscribe(data => {
      if (data['estatus'] == 200) {

        console.log("estatus 200-OK");
      }
      else {
        console.log("fue error");
        return;
      }
    });

    //$.ajax({
    //  type: "POST",
    //  url: '/api/cotizador/CalcularCAT',
    //  data: 15,
    //  success: function (d) {
    //    if (d.success == true)
    //      console.log("FUE SUCCESS");
    //    else {
    //      console.log("FUE ELSE");
    //    }
    //  },
    //  error: function (xhr, textStatus, errorThrown) {
    //    // TODO: Show error
    //    console.log("FUE ERROR");
    //  }
    //});


    const contacto = {
      nombreContacto: "Adrian",
    };
    //console.log(data1);
    //$.ajax({
    //  type: "POST",
    //  url: '/api/cotizador/CalcularCAT',
    //  dataType: 'json',
    //  contentType: "application/json; charset=utf-8",
    //  data: CotizadorForm,
    //  success: function (data2) {
    //    console.log(data2);
    //  },
    //  error: function (data2) {
    //    alert('error');
    //    console.log(data2);
    //  }
    //});

    //this.http.post(`${this.baseUrl}api/cotizador/CalcularCAT`, contacto, { headers: reqHeader }).pipe(map((response: CorreoResponse) => {
    //  console.log(response);
    //  return response

    //}));

  }

  //Intento AJAX (FIN)

  //Aquí va todo lo del CAT
  /*************
 *
 * @amount => Cantidad prestada neta(No se restan cargos ni se suman intereses)
 * @charge => Cargos por apertura
 * @byPeriod => Pago periódico
 * @totalPayments => Total de pagos(Años, Meses, Quincenas, Días)
 * @periodsByYear => Periodos por año de acuerdo a Banxico(Días: 360, Semanas: 52, Quincenas: 24, Meses: 12, Trimestres: 4)
 *
 *************/
  GetCAT(saldoFinanciado, comisionApertura, Mensualidad, PlazoCredito, PeriodosPorAnio) {
    var cat = 50, cat_min = 0, cat_max = 0, temp_cat;

    var counter = 0, tempVal;

    do {
      tempVal = this.getCATValue(saldoFinanciado, comisionApertura, Mensualidad, PlazoCredito, PeriodosPorAnio, cat);
      //console.log("tempVal"+tempVal);
      if (tempVal > 0) {
        //console.log("entra al if");
        temp_cat = cat;
        //console.log("if: temp_cat " + temp_cat);
        cat = (cat + cat_min) / 2;
        //console.log("if: cat " + cat);
        cat_max = temp_cat;
      } else if (cat_max == 0) {
        //console.log("ELSE IF");
        cat_min = cat;
        cat = cat + 50;
      } else {
        //console.log("Entró al ELSEE");
        temp_cat = cat;
        cat = (cat + cat_max) / 2;
        cat_min = temp_cat;
      }
      counter++;
    } while (counter <= 25);

    return { num: cat, formateado: cat.toFixed(2) }
  }



  getCATValue(saldoFinanciado, comisionApertura, Mensualidad, PlazoCredito, PeriodosPorAnio, cat) {
    var i = 0;

    var value = comisionApertura / Math.pow((1 + (cat / 100)), 0 / PeriodosPorAnio);
    //console.log("VALUEEE " + value);
    for (i = 1; i <= PlazoCredito; i++) {
      var primerValor = (1 + (cat / 100));
      //console.log("primer valor: " + primerValor);
      var NUMAUX = Math.pow(i, 2);
      //console.log("NUMAUX " + NUMAUX);
      value += Mensualidad / Math.pow((1 + (cat / 100)), i / PeriodosPorAnio);

    }
    return saldoFinanciado - value;
  }
  //Aqui acaban las funciones del CAT

  ValidarQueEstenLlenosLosCampos() {
    if (InputNombreLocal == "" || InputTelefonoLocal == "" || InputCorreoLocal == "" || ComboEstadoLocal == "" ||
      ComboMarcaLocal == "" || InputModeloLocal == "" || ComboAnioLocal == "" || InputMontoLocal == "" ||
      InputEngancheLocal == "" || InputSaldoFinanciadoLocal == "" || ComboPlazoLocal == "" || InputCPLocal == "" || AnioAutoLocal == "") {
      this.router.navigate([]).then(() => { window.open('/cotizador'); });
    }

  }
  //2.-AQUI SE SETEAN LOS DATOS A LOS ELEMENTOS
  CargarDatos() {
    //NOMBRE
    var element1 = document.getElementById("labelNombre");
    element1.textContent = "Nombre del Cliente: " + InputNombreLocal;
    //CORREO
    var element2 = document.getElementById("labelCorreo");
    element2.textContent = "Correo Electronico: " + InputCorreoLocal;
    //ESTADO
    var element3 = document.getElementById("labelEstado");
    element3.textContent = "Estado: " + ComboEstadoLocal;
    //TELEFONO
    var element4 = document.getElementById("labelTelefono");
    element4.textContent = "Telefono: " + InputTelefonoLocal;
    //MODELO AUTO
    var element5 = document.getElementById("labelModelo");
    element5.textContent = "Vehículo a Financiar: " + ComboMarcaLocal + ", " + InputModeloLocal;
    //MODELO AÑO
    var element6 = document.getElementById("labelAnio");
    element6.textContent = "Modelo: " + ComboAnioLocal;

    //---------TABLA1----------
    //Valor del Automovil
    InputMontoLocalFormateado = this.formatear(InputMontoLocal);
    var element7 = document.getElementById("labelValorDelAutomovil");
    element7.textContent = InputMontoLocalFormateado;
    //Enganche
    InputEngancheLocalFormateado = this.formatear(InputEngancheLocal);
    var element8 = document.getElementById("labelEnganche");
    element8.textContent = InputEngancheLocalFormateado;
    //Saldo Financiado
    InputSaldoFinanciadoLocalFormateado = this.formatear(InputSaldoFinanciadoLocal);
    var element9 = document.getElementById("labelSaldoFinanciado");
    element9.textContent = InputSaldoFinanciadoLocalFormateado;
    //Plazo
    var element10 = document.getElementById("labelPlazo");
    element10.textContent = ComboPlazoLocal;
    //Comision
    var element10 = document.getElementById("labelComision");
    element10.textContent = this.formatear(ComisionPorApertura.toString());
    //Tasa
    //por hacer: si tipo auto A se queda igual, else 32% la tasa
    if (ComboTipoAutoLocal == "auto") {
      var element11 = document.getElementById("labelTasa");
      element11.textContent = "16%";
    }
    else {
      var element11 = document.getElementById("labelTasa");
      element11.textContent = "45%";
    }

    var element12 = document.getElementById("labelPeriodoString");
    element12.textContent = PeriodoString;

    //FECHA
    const fecha = new Date();
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    const months = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre'
    ]
    const dia = hoy.getDate();
    var mesIndex = hoy.getMonth();
    const mesNombre = months[mesIndex];
    const anio = hoy.getFullYear();
    var diaString, mesIndexString = "";

    var element11 = document.getElementById("labelFecha");
    element11.textContent = dia + " de " + mesNombre + " de " + anio;
    if (dia.toString().length == 1) {
      diaString = "0" + dia;
    }
    else {
      diaString = dia;
    }
    mesIndex++;
    if (mesIndex.toString().length == 1) {
      mesIndex
      mesIndexString = "0" + mesIndex;
    }
    else {
      mesIndexString = mesIndex.toString();
    }
    FechaDeCalculo = diaString + "/" + mesIndexString + "/" + anio;

    this.calcularMensualidad();

  }

  crearTablaAmortizacion() {
    this.SaldoRestante = InputSaldoFinanciadoLocal;
    var IVA = 0;
    var Capital = 0;
    var IVAStr = "";
    var CapitalStr = "";
    var InteresesStr = "";
    var SaldoRestanteStr = "";

    var TasaMensualSinIva = 0;

    // TASA ANUAL SEGUN EL TIPO DE VEHICULO
    if (ComboTipoAutoLocal == "auto") {
      TasaAnual = this.TasaInteresAnualAuto;
    } else {
      TasaAnual = this.TasaInteresAnualMoto;
    }

    // Ajuste de tasa según el periodo de pago
    var tasaPeriodo = TasaAnual / this.NoPagosAlAnioMes;
    var periodosPorAno = this.NoPagosAlAnioMes; // Por defecto mensual

    switch (Periodo) {
      case '2': // Quincenal
        periodosPorAno = this.NoPagosAlAnioQuincenas;
        tasaPeriodo = TasaAnual / this.NoPagosAlAnioQuincenas;
        break;
      case '3': // Semanal
        periodosPorAno = this.NoPagosAlAnioSemanas;
        tasaPeriodo = TasaAnual / this.NoPagosAlAnioSemanas;
        break;
    }

    // Ajustar totalPagos para que sea el número correcto de periodos según el plazo
    var totalPagos = NoDeAnioss * periodosPorAno; // Total de pagos según el periodo y número de años

    //Ciclo para los pagos
    for (let i = 1; i <= totalPagos; i++) {
      Intereses = this.SaldoRestante * tasaPeriodo;
      SumatoriaIntereses += Intereses;
      IVA = Intereses * 0.16;
      Capital = MensualidadT2 - Intereses - IVA;
      SumatoriaCapital += Capital;
      InteresesStr = this.formatear(Intereses);
      SaldoRestanteStr = this.formatear(this.SaldoRestante);
      CapitalStr = this.formatear(Capital);
      IVAStr = this.formatear(IVA);
      var tr = document.createElement('tr');

      tr.innerHTML = `<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin-top: 0.5em; margin-bottom: 0em;">${i}</td>
                      <td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin-top: 0.5em; margin-bottom: 0em;">${SaldoRestanteStr}</td>
                      <td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin-top: 0.5em; margin-bottom: 0em;">${CapitalStr}</td>
                      <td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin-top: 0.5em; margin-bottom: 0em;">${InteresesStr}</td>
                      <td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin-top: 0.5em; margin-bottom: 0em;">${IVAStr}</td>
                      <td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin-top: 0.5em; margin-bottom: 0em;">${this.formatear(MensualidadT2)}</td>`;

      var tbody = document.getElementById('tablaAmortizacion').getElementsByTagName('tbody')[0];
      tbody.appendChild(tr);

      this.SaldoRestante -= Capital;
    }

    TotalInteresesMasCapital = SumatoriaCapital + SumatoriaIntereses;
    MensualidadCAT = TotalInteresesMasCapital / totalPagos;
    console.log("Sumatoria Capital: " + SumatoriaCapital);
    console.log("Sumatoria Intereses: " + SumatoriaIntereses);
    console.log("Total Capital + Intereses: " + TotalInteresesMasCapital);
    console.log("Mensualidad CAT: " + MensualidadCAT);
    var br = document.createElement('br');
    var elementoTabla = document.getElementById('tablaAmortizacion');
    elementoTabla.after(br);
  }


 


  formatear(number) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
  }

  calcularMensualidad() {
    let mens = 0;
    // Monto
    const montoFinanciado = InputSaldoFinanciadoLocal;
    console.log("monto Financiado 2: ", montoFinanciado);
    // Años (ComboPlazoLocal es igual al plazo, osea NoMensualidades)
    //const anios = NoDeAnios / 12;
    const anios = NoDeAnioss;
    console.log("ComboPlazoLocal :", ComboPlazoLocal);
    console.log("anios 2 :",anios)
    // Pagos Anuales
    let NoPagosAnuales = 12;

    // Tasa de Interés Anual
    let TasaAnual = 0;
    if (ComboTipoAutoLocal === "auto") {
      TasaAnual = this.TasaInteresAnualAuto;
    } else {
      TasaAnual = this.TasaInteresAnualMoto;
    }

    // Tasa de Interés ajustada
    const TasaInteres = TasaAnual * 1.16;
    console.log("periodo 2: ", Periodo);
    // Ajuste para diferentes periodos
    switch (Periodo) {
      case '1': // Mensual
        NoPagosAnuales = 12;
        break;
      case '2': // Quincenal
        NoPagosAnuales = 26;
        break;
      case '3': // Semanal
        NoPagosAnuales = 52;
        break;
    }

    // Tipo de interés fraccionado (del periodo)
    let im = TasaInteres / NoPagosAnuales;

    const im2 = Math.pow((1 + im), -(NoPagosAnuales * anios));

    // Cuota Cap. + Int.
    mens = (montoFinanciado * im) / (1 - im2);
    console.log("mens 2", mens)

    // Actualizar valores de la UI
    MensualidadT2 = mens;
    MensualidadLocal = this.formatear(mens);
    console.log("mensualidad formateada 2:", MensualidadLocal);
    const element11 = document.getElementById("labelMensualidad");
    if (element11) {
      element11.textContent = MensualidadLocal;
    }
  }

  ngOnInit(): void {
    //alert("entre OnInit");
    this.CrearPDFServ.disparadorDeFavoritos.subscribe(data => {
      //console.log("recibiendo data", data);
      //data
      //InputNombreLocal = data['data']['InputNombre'];
      //alert("data vale: " + data['data']['InputNombre']);
      //alert("NombreCliente2 vale: " + InputNombreLocal);
    });
    //TIMER
    setTimeout(() => {
      //AQUI RECUPERAMOS LOS VALORES ENVIADOS DESDE cotizador.component.ts
      let JSONTex = localStorage.getItem('cotizacion');
      let newObject = JSON.parse(JSONTex);
      console.log("cotizacion RECIBIDA", newObject)

      this.setearValores(newObject);
      //this.CargarDatos();
      this.CargarDatos2();
      //this.CalcularCAT();
      //var cat = this.GetCAT(InputSaldoFinanciadoLocal, 0, MensualidadCAT, ComboPlazoLocal, 12);
      //Valores de prueba con CAT conocido del 41.3%
      //var cat = this.GetCAT(10000, 0, 1000, 12, 12);
      var cat = this.GetCAT(80000, 0, 7260.69, 12, 12);
      console.log("EL CAT ES: ", cat);
      if (ComboTipoAutoLocal == "auto") {
        //alert("Si es auto" + ComboTipoAutoLocal);
        var element13 = document.getElementById("labelCAT");
        var elememt14 = document.getElementById("DivPrePieDePagina1");
        var PiePaginaNegrita = "Tasa de interes moratoria: 32%, " + "CAT: " + cat.formateado + "%, " + "Fecha de calculo: " + FechaDeCalculo + ", Tipo de tasa: Fija, Tipo de crédito: Crédito Automotriz, Comisión por apertura: 3%. ";
        var PiePaginaSinNegrita = " Incumplir tus obligaciones te puede generar comisiones e intereses moratorios. Contratar créditos que excedan tu capacidad de pago afecta tu historial crediticio.El presente documento no constituye una oferta.Sujeta a aprobación de crédito.El avalista, obligado solidario o coacreditado responderá como obligado principal frente a la entidad financiera.Pago tardio(mora) será la tasa de interes ordinaria multiplicada por 2. Comisión por reclamacion improcedente $100.00 pesos.Comisión por cobranza $50.00 pesos. ACLARACIONES Y RECLAMACIONES UNIDAD ESPECIALIZADA DE ATENCION A USUARIOS: DOMICILIO: Boulevard Gral.Pedro Verona Rodriguez Triana No.1900, interior 40 Col.Rincon la Merced Torreón,Coahuila C.P. 27294 TELÉFONO: (871) 204 08 67 CORREO ELECTRONICO: contacto@creditoespecializado.com. COBERTURA NACIONAL."
        //element13.textContent = "Tasa de interes moratoria: 32%, " + "CAT: " + cat.formateado + "%, " + "Fecha de calculo: " + FechaDeCalculo + ", Tipo de tasa: Fija, Tipo de crédito: Crédito Automotriz, Comisión por apertura: 3%. ";
        elememt14.innerHTML = "<p style='font-size: 10px'><b>" + PiePaginaNegrita + "</b>" + PiePaginaSinNegrita + "<b> Terminos y condiciones, aplican restricciones consultar en </b>www.creditoespecializado.com.</p>"
        elememt14.className = "col-lg-11";
      }
      else {
        //alert("no es auto" + ComboTipoAutoLocal);
        var element13 = document.getElementById("labelCAT");
        var elememt14 = document.getElementById("DivPrePieDePagina1");
        var PiePaginaNegrita = "Tasa de interés moratoria: 70%," + "CAT: " + cat.formateado + "%, " + "Fecha de calculo: " + FechaDeCalculo + ", Tipo de tasa: Fija, Tipo de crédito: Crédito Moto, Comisión por apertura: 3%. ";
        var PiePaginaSinNegrita = " Incumplir tus obligaciones te puede generar comisiones e intereses moratorios. Contratar créditos que excedan tu capacidad de pago afecta tu historial crediticio.El presente documento no constituye una oferta.Sujeta a aprobación de crédito.El avalista, obligado solidario o coacreditado responderá como obligado principal frente a la entidad financiera.Pago tardio(mora) será la tasa de interes ordinaria multiplicada por 2. Comisión por reclamacion improcedente $100.00 pesos.Comisión por cobranza $50.00 pesos. ACLARACIONES Y RECLAMACIONES UNIDAD ESPECIALIZADA DE ATENCION A USUARIOS: DOMICILIO: Boulevard Gral.Pedro Verona Rodriguez Triana No.1900, interior 40 Col.Rincon la Merced Torreón,Coahuila C.P. 27294 TELÉFONO: (871) 204 08 67 CORREO ELECTRONICO: contacto@creditoespecializado.com. COBERTURA NACIONAL."
        //element13.textContent = "Tasa de interes moratoria: 32%, " + "CAT: " + cat.formateado + "%, " + "Fecha de calculo: " + FechaDeCalculo + ", Tipo de tasa: Fija, Tipo de crédito: Crédito Automotriz, Comisión por apertura: 3%. ";
        elememt14.innerHTML = "<p style='font-size: 10px'><b>" + PiePaginaNegrita + "</b>" + PiePaginaSinNegrita + "<b> Terminos y condiciones, aplican restricciones consultar en </b>www.creditoespecializado.com.</p>"
        elememt14.className = "col-lg-11";
      }

      this.crearTablaAmortizacion();
    }, 500)
  }

  buildForm() {
    this.formularioContacto = this.formBuilder.group({
      InputNombre: ['', [Validators.required]],
      InputTelefono: ['', [Validators.required]],
      InputCorreo: ['', [Validators.required]],
      InputCP: ['', [Validators.required]],
      ComboEstado: ['', [Validators.required]],
      ComboTipoAuto: ['', [Validators.required]],
      ComboAnio: ['', [Validators.required]],
      ComboMarca: ['', [Validators.required]],
      InputModelo: ['', [Validators.required]],
      InputMonto: ['', [Validators.required]],
      InputEnganche: ['', [Validators.required]],
      ComboPlazo: ['', [Validators.required]],
      ComboPlazoPeriodo: ['', [Validators.required]]
    });
  }

  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }


  download() {
    var element = document.getElementById('table');
    var opt = {
      margin: 1,
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    // New Promise-based usage:
    this.html2pdf().from(element).set(opt).save();


  }

  EnviarAttachedFile() {
    this.alertService.success('Enviando correo, por favor espere', { autoClose: true });
    (<HTMLInputElement>document.getElementById("BtnEnviarPorCorreo")).disabled = true;
    var string64 = (<HTMLInputElement>document.getElementById("InputPDF64")).value
    console.log(string64);

    this.insR.EnviarCorreoCotizacionConPDF(InputNombreLocal, InputTelefonoLocal, InputCorreoLocal, ComboEstadoLocal, InputCPLocal, ComboTipoAutoLocal, AnioAutoLocal, ComboMarcaLocal, InputModeloLocal, InputMontoLocalFormateado, InputEngancheLocalFormateado, InputSaldoFinanciadoLocalFormateado, ComboPlazoLocalAux, string64).subscribe(data => {
      console.log('la data contacto: ', data)
      if (data['estatus'] == 200) {
        alert('Se ha enviado un correo a un asesor, pronto se contactarán contigo');

        console.log("estatus 200-OK");
        this.SpinnerService.hide();
        this.alertService.success('Correo enviado correctamente', { autoClose: true });
        (<HTMLInputElement>document.getElementById("BtnEnviarPorCorreo")).disabled = false;
      }
      else {
        console.log("fue error");
        this.alertService.error('Se registro un error al guardar correo', { autoClose: true });
        this.SpinnerService.hide();
        (<HTMLInputElement>document.getElementById("BtnEnviarPorCorreo")).disabled = false;
        return;
      }
    });




  }

  funcionEstaticaCalculo() {
    console.log("Entró a funcionEstaticoCalculo");
    // Obtener los valores del formulario
    const precioAutoValor = this.formularioContacto.get('InputMonto').value;
    const engancheValor = this.formularioContacto.get('InputEnganche').value;
    const plazoValor = this.formularioContacto.get('ComboPlazo').value;
    const periodoValor = this.formularioContacto.get('ComboPlazoPeriodo').value;
    const tipoVehiculoValor = this.formularioContacto.get('ComboTipoAuto').value;

    console.log('precioAutoValor:', precioAutoValor);
    console.log('engancheValor:', engancheValor);
    console.log('plazoValor:', plazoValor);
    console.log('periodoValor:', periodoValor);
    console.log('tipoVehiculoValor:', tipoVehiculoValor);

    // Validar si los valores son válidos
    if (!precioAutoValor) {
      document.getElementById("labelMensualidad").innerText = " ";
      console.log('El campo "Precio del vehículo" está vacío o no es válido.');
      return;
    }
    if (!engancheValor) {
      document.getElementById("labelMensualidad").innerText = " ";
      console.log('El campo "Enganche" está vacío o no es válido.');
      return;
    }
    if (!plazoValor) {
      document.getElementById("labelMensualidad").innerText = " ";
      console.log('El campo "Plazo" está vacío o no es válido.');
      return;
    }
    if (!periodoValor) {
      document.getElementById("labelMensualidad").innerText = " ";
      console.log('El campo "Periodo" está vacío o no es válido.');
      return;
    }
    if (!tipoVehiculoValor) {
      document.getElementById("labelMensualidad").innerText = " ";
      console.log('El campo "Tipo de vehículo" está vacío o no es válido.');
      return;
    }

    // Limpiar y convertir los valores a números
    const precioAuto: number = this.limpiarValor(precioAutoValor);
    const enganche: number = this.limpiarValor(engancheValor);
    const numeroMeses: number = +plazoValor;
    const periodoPago: number = +periodoValor;
    const tipoVehiculo: string = tipoVehiculoValor;

    console.log('precioAuto:', precioAuto);
    console.log('enganche:', enganche);
    console.log('numeroMeses:', numeroMeses);
    console.log('periodoPago:', periodoPago);
    console.log('tipoVehiculo:', tipoVehiculo);

    // Monto financiado (precio del auto menos el enganche)
    const montoFinanciado: number = precioAuto - enganche;
    console.log('montoFinanciado:', montoFinanciado);

    // Comisión por apertura (3% del monto financiado)
    const comisionSinIVA: number = montoFinanciado * 0.03;
    console.log('comisionSinIVA:', comisionSinIVA);

    // IVA sobre la comisión (16% de la comisión sin IVA)
    const IVA: number = comisionSinIVA * 0.16;
    console.log('IVA:', IVA);

    // Comisión total (comisión sin IVA más el IVA)
    const comisionTotal: number = comisionSinIVA + IVA;
    console.log('comisionTotal:', comisionTotal);

    // Monto total a financiar (monto financiado más la comisión total)
    const montoTotalFinanciado: number = montoFinanciado + comisionTotal;
    console.log('montoTotalFinanciado:', montoTotalFinanciado);

    // Tasa de interés anual según el tipo de vehículo
    let tasaInteresAnual: number;
    if (tipoVehiculo === 'auto') {
      tasaInteresAnual = this.TasaInteresAnualAuto;
    } else if (tipoVehiculo === 'moto') {
      tasaInteresAnual = this.TasaInteresAnualMoto;
    }
    console.log('tasaInteresAnual:', tasaInteresAnual);

    // Tasa de interés según el periodo de pago y número total de pagos
    let tasaInteresPeriodo: number;
    let numPagosTotales: number;
    if (periodoPago === 1) { // Mensual
      tasaInteresPeriodo = tasaInteresAnual / 12;
      numPagosTotales = numeroMeses;
    } else if (periodoPago === 2) { // Quincenal
      tasaInteresPeriodo = tasaInteresAnual / 26;
      numPagosTotales = numeroMeses * 2;
    } else if (periodoPago === 3) { // Semanal
      tasaInteresPeriodo = tasaInteresAnual / 52;
      numPagosTotales = numeroMeses * 4.33;
    }
    console.log('tasaInteresPeriodo:', tasaInteresPeriodo);
    console.log('numPagosTotales:', numPagosTotales);

    // Factor de descuento
    const factorDescuento: number = Math.pow((1 + tasaInteresPeriodo), -numPagosTotales);
    console.log('factorDescuento:', factorDescuento);

    // Mensualidad (con interés compuesto)
    const mensualidad: number = (montoTotalFinanciado * tasaInteresPeriodo) / (1 - factorDescuento);
    MensualidadT2 = mensualidad;
    console.log('mensualidad:', mensualidad);

    // Imprimir el resultado
    document.getElementById("labelMensualidad").innerText = this.formatear(mensualidad.toFixed(2).toString()); 

    console.log(`La mensualidad es de aproximadamente: $${mensualidad.toFixed(2)} MXN`);
  }

  limpiarValor(valor: string): number {
    return parseFloat(valor.replace(/[$,]/g, ''));
  }

  CargarDatos2() {
    console.log("entró a cargar datos 2");
    //var nombre = this.formularioContacto.get('InputNombre').value;
    //console.log("nombre 2", nombre);
    //NOMBRE
    var element1 = document.getElementById("labelNombre");
    element1.textContent = "Nombre del Cliente: " + this.formularioContacto.get('InputNombre').value;
    //CORREO
    var element2 = document.getElementById("labelCorreo");
    element2.textContent = "Correo Electronico: " + this.formularioContacto.get('InputCorreo').value;
    //ESTADO
    var element3 = document.getElementById("labelEstado");
    element3.textContent = "Estado: " + this.formularioContacto.get('ComboEstado').value;
    //TELEFONO
    var element4 = document.getElementById("labelTelefono");
    element4.textContent = "Telefono: " + this.formularioContacto.get('InputTelefono').value;
    //MODELO AUTO
    var element5 = document.getElementById("labelModelo");
    element5.textContent = "Vehículo a Financiar: " + this.formularioContacto.get('ComboMarca').value; + ", " + this.formularioContacto.get('InputModelo').value;
    //MODELO AÑO
    var element6 = document.getElementById("labelAnio");
    element6.textContent = "Modelo: " + this.formularioContacto.get('ComboAnio').value;

    //---------TABLA1----------
    //Valor del Automovil
    InputMontoLocalFormateado = this.formatear(InputMontoLocal);
    var element7 = document.getElementById("labelValorDelAutomovil");
    element7.textContent = InputMontoLocalFormateado;
    //Enganche
    InputEngancheLocalFormateado = this.formatear(InputEngancheLocal);
    var element8 = document.getElementById("labelEnganche");
    element8.textContent = InputEngancheLocalFormateado;
    //Saldo Financiado
    InputSaldoFinanciadoLocalFormateado = this.formatear(InputSaldoFinanciadoLocal);
    var element9 = document.getElementById("labelSaldoFinanciado");
    element9.textContent = InputSaldoFinanciadoLocalFormateado;
    //Plazo
    var element10 = document.getElementById("labelPlazo");
    element10.textContent = ComboPlazoLocal;
    //Comision
    var element10 = document.getElementById("labelComision");
    element10.textContent = this.formatear(ComisionPorApertura.toString());
    //Tasa
    //por hacer: si tipo auto A se queda igual, else 32% la tasa
    if (ComboTipoAutoLocal == "auto") {
      var element11 = document.getElementById("labelTasa");
      element11.textContent = "16%";
    }
    else {
      var element11 = document.getElementById("labelTasa");
      element11.textContent = "45%";
    }

    var element12 = document.getElementById("labelPeriodoString");
    element12.textContent = PeriodoString;

    //FECHA
    const fecha = new Date();
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    const months = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre'
    ]
    const dia = hoy.getDate();
    var mesIndex = hoy.getMonth();
    const mesNombre = months[mesIndex];
    const anio = hoy.getFullYear();
    var diaString, mesIndexString = "";

    var element11 = document.getElementById("labelFecha");
    element11.textContent = dia + " de " + mesNombre + " de " + anio;
    if (dia.toString().length == 1) {
      diaString = "0" + dia;
    }
    else {
      diaString = dia;
    }
    mesIndex++;
    if (mesIndex.toString().length == 1) {
      mesIndex
      mesIndexString = "0" + mesIndex;
    }
    else {
      mesIndexString = mesIndex.toString();
    }
    FechaDeCalculo = diaString + "/" + mesIndexString + "/" + anio;

    //this.calcularMensualidad();
    this.funcionEstaticaCalculo();
  }



}
