import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Alert, AlertService } from '../_alert';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cotizador-simulador',
  templateUrl: './cotizador-simulador.component.html',
  styleUrls: ['./cotizador-simulador.component.css']
})
export class CotizadorSimuladorComponent implements OnInit {

  formularioContacto: FormGroup;
  PlazoMeses: number = 0;
  PlazoQuincenas: number = 0;
  PlazoSemanas: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    protected alertService: AlertService,
    private router: Router,
  ) { this.buildForm(); }

  buildForm() {
    this.formularioContacto = this.formBuilder.group({
      InputMeses: ['', [Validators.required]],
      InputQuincenas: ['', [Validators.required]],
      InputSemanas: ['', [Validators.required]],
      InputPago: ['', [Validators.required]],
      InputOfertaSemanal: ['', [Validators.required]],
      InputOfertaQuincenal: ['', [Validators.required]],
      InputOfertaMensual: ['', [Validators.required]],
      terminos: ['', [Validators.required]]
    });
  }

  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }


  ngOnInit() {
    //this.loadScript('assets/plugins/sweetalert/sweetalert-dev.js');
    this.loadScript('assets/js/validaciones.js');
    this.loadScript('assets/js/Simulador.js');
    document.getElementById("InputMeses").focus();
  }

  CrearTabla() {


    const form = this.formularioContacto.value;
    if (!form.terminos) {
      this.alertService.warn('Favor de aceptar Aviso de privacidad', { autoClose: true });
      return;
    }
    else {
      //Si esta lleno el formulario enviará el email
      //if (this.ValidarMontos()) 
        //this.SpinnerService.hide();

        console.log("cform enviado", form);
      form.InputOfertaSemanal = (<HTMLInputElement>document.getElementById("InputOfertaSemanal")).value;
      form.InputOfertaQuincenal = (<HTMLInputElement>document.getElementById("InputOfertaQuincenal")).value;
      form.InputOfertaMensual = (<HTMLInputElement>document.getElementById("InputOfertaMensual")).value;
        //form.InputEnganche = EngancheLocal;
        //this.CrearPDFServ.disparadorDeFavoritos.emit({
        //  data: form
        //});
      console.log("form", form);
      //Guardo en esta variable el form para tomarlo en CrearPDFSimulador
        localStorage.setItem('cotizacionSimulacion', JSON.stringify(form));

        //this.router.navigateByUrl('/crearPDF');
      this.router.navigate([]).then(result => { window.open('/crear-pdfsimulador', '_blank'); });
        //this.router.navigate([]).then(result => { window.open('/crear-pdfsimulador', '_blank'); });
      //}
    }

  }

  CalcularPlazos(combo) {
    //this.PlazoMeses = combo;
  }



}
