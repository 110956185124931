import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../token/token.service';
import { tap, map } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Inject } from '@angular/core';


 
export interface EditUserDto {
  id: string;
  nombre: string;
  agencia: string;
  email: string;
}
export class RegistroService {
  private userName = new BehaviorSubject<string>(null);
  private isLogged = new BehaviorSubject<boolean>(null);

  private nameTeam = new BehaviorSubject<string>(null);
  private liderEquipo = new BehaviorSubject<string>(null);

  url = '/api/registro';

  nameTeam$ = this.nameTeam.asObservable();
  liderEquipo$ = this.liderEquipo.asObservable();

  userName$ = this.userName.asObservable();
  isLogged$ = this.isLogged.asObservable();

  constructor(
    @Inject('BASE_URL') public baseUrl: string,
    @Inject(HttpClient) private http: HttpClient,
    private token: TokenService
  ) { }

  createUser(email: string, password: string) {
    // return this.af.auth.createUserWithEmailAndPassword(email, password);
  }

  login(userName: string, password: string) {
    
  }
  Registro(nombre: string, asunto: string, telefono: string, area: string,
    archivo: File, rutaArchivo: string, extArchivo: string): Observable<RegistroResponse> {
    debugger;
    console.log('Entre insert')
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
     // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    });
    const contacto = {
      nombreContacto: nombre,
      area: area, 
      telefonoContacto: telefono,
      asuntoCorreo: asunto,
      mensajeCorreo: "",
      archivo: archivo,
      rutaArchivo: rutaArchivo,
      extArchivo: extArchivo,
      tipoCorreo: "bolsaTrabajo",
      emailContacto: ""
    }

    console.log(contacto)

    return this.http.post(`${this.baseUrl}api/contacto/UploadFiles`, contacto, { headers: reqHeader }).pipe(map((response: RegistroResponse) => {
      return response
    }));
  }

  EnviarCorreo(nombre: string, telefono: string, email: string, mensaje: string, asunto:string): Observable<CorreoResponse> {
    //debugger;
    console.log('Entre enviar correo')
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    });
    const contacto = {
      nombreContacto: nombre,
      emailContacto: email,
      telefonoContacto: telefono,
      mensajeCorreo: mensaje,
      tipoCorreo: "contacto", 
      rutaArchivo: "",
      apellidoContacto: "",
      asuntoCorreo: "Solicitud de " + asunto,
      archivo: null,
      extArchivo: "",
      area: ""
    }

    console.log(contacto)

    return this.http.post(`${this.baseUrl}api/contacto/EnviarEmail`, contacto, { headers: reqHeader }).pipe(map((response: CorreoResponse) => {
      return response
    }));
  }


  logout() {
    this.token.removeAll();
    this.load();
  }

  load() {
    const token = this.token.getToken();
    const userName = this.token.getUserName();
    const isLogged = token === null ? false : true;
    this.userName.next(userName);
    this.isLogged.next(isLogged);
    return isLogged;
  }

  hasUser(): Observable<boolean> {
    return of(this.load());
  }

  EnviarCorreoCotizacion(nombre: string, telefono: string, email: string, estado: string, cp: string, tipoAuto: string, anioAuto: string, marcaAuto: string, modeloAuto: string, valorAuto: string, enganche: string, montoAPrestar: string, plazoPago: string): Observable<CorreoResponse> {
    //debugger;
    console.log('Entre enviar correo cotizacion 2');
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    });

    const contacto = {
      nombreContacto: nombre,
      telefonoContacto: telefono,
      emailContacto: email,
      tipoCorreo: "contizacion",
      estado: estado,
      cp: cp,
      tipoAuto: tipoAuto,
      anioAuto: anioAuto,
      marcaAuto: marcaAuto,
      modeloAuto: modeloAuto,
      valorAuto: valorAuto,
      enganche: enganche,
      montoAPrestar: montoAPrestar,
      plazoPago: plazoPago
    }

    console.log("el año 2:"+contacto.anioAuto);

    return this.http.post(`${this.baseUrl}api/cotizador/EnviarEmailCotizacion`, contacto, { headers: reqHeader }).pipe(map((response: CorreoResponse) => {
      return response
    }));
  }

  EnviarCorreoCotizacionConPDF(nombre: string, telefono: string, email: string, estado: string, cp: string, tipoAuto: string, anioAuto: string, marcaAuto: string, modeloAuto: string, valorAuto: string, enganche: string, montoAPrestar: string, plazoPago: string, PDF: string): Observable<CorreoResponse> {
    //debugger;
    console.log('Entre enviar correo cotizacion 2');
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    });
    const contacto = {
      nombreContacto: nombre,
      telefonoContacto: telefono,
      emailContacto: email,
      tipoCorreo: "contizacion",
      estado: estado,
      cp: cp,
      tipoAuto: tipoAuto,
      anioAuto: anioAuto,
      marcaAuto: marcaAuto,
      modeloAuto: modeloAuto,
      valorAuto: valorAuto,
      enganche: enganche,
      montoAPrestar: montoAPrestar,
      plazoPago: plazoPago,
      PDF :PDF
    }

    console.log("el año 2:" + contacto.anioAuto);

    return this.http.post(`${this.baseUrl}api/cotizador/EnviarEmailCotizacionConPDF`, contacto, { headers: reqHeader }).pipe(map((response: CorreoResponse) => {
      return response
    }));
  }

  CrearPDF(nombre: string, telefono: string, email: string, estado: string, cp: string, tipoAuto: string, anioAuto: string, marcaAuto: string, modeloAuto: string, valorAuto: string, enganche: string, montoAPrestar: string, plazoPago: string): Observable<CorreoResponse> {
    //debugger;
    console.log('Entre crearPDF');
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    });
    const contacto = {
      nombreContacto: nombre,
      telefonoContacto: telefono,
      emailContacto: email,
      tipoCorreo: "contizacion",
      estado: estado,
      cp: cp,
      tipoAuto: tipoAuto,
      anioAuto: anioAuto,
      marcaAuto: marcaAuto,
      modeloAuto: modeloAuto,
      valorAuto: valorAuto,
      enganche: enganche,
      montoAPrestar: montoAPrestar,
      plazoPago: plazoPago
    }

    console.log("el año 2:" + contacto.anioAuto);

    return this.http.post(`${this.baseUrl}api/cotizador/CrearPDF`, contacto, { headers: reqHeader }).pipe(map((response: CorreoResponse) => {
      console.log(response);
      return response;
    }));
  }

  CalcularCAT(monto: string, comision: string, plazo: string,mensu): Observable<CorreoResponse> {
    //debugger;
    console.log('Entre enviar CAT 2' + plazo);

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    });
    const contacto = {
      montoAPrestar: monto,
      ComisionPorApertura: comision,
      plazoPago: plazo,
      Mensualidad: mensu
    }


    return this.http.post(`${this.baseUrl}api/cotizador/CalcularCATT`, contacto, { headers: reqHeader }).pipe(map((response: CorreoResponse) => {
      return response
    }));
  }

}

export interface RegistroResponse {
  estatus: number;
  mensaje: string;
}

export interface CorreoResponse {
  estatus: number;
  mensaje: string;
}
