import { Component, OnInit,Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';


export interface Pesos {
  nombreIntegrante: string,
  pesoInicial: string,
  semana1: string,
  semana2: string,
  semana3: string,
  semana4: string,
  semana5: string,
  pesoFinal: string
}
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html'
  //styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  tipo: number;
  requisitos: string[] = [];
  condiciones: string[] = [];

  constructor(public dialogRef: MatDialog, @Inject(MAT_DIALOG_DATA) data) {
    //debugger;
    this.tipo = data.tipo;
  }
  ngOnInit(): void {
    //debugger;
    switch (this.tipo) {
      case 1: //this.getTipo1();
        this.requisitos.push("Trabajar en una empresa con convenio con Crédito Especializado");
        this.requisitos.push("Ine/pasaporte vigente");
        this.requisitos.push("Comprobante de domicilio");
        this.requisitos.push("Recibos de nómina");

        this.condiciones.push("Plazo: hasta 24 meses");
        this.condiciones.push("Tasa fija de 48% anual");
        this.condiciones.push("Sin comisiones");
        this.condiciones.push("Monto de hasta 150 mil pesos.");
        break;
      case 2: this.getTipo2();
        break;
      case 3: this.getTipo3();
        break;
    }
  }
  
  getTipo1(){
    this.requisitos.push("Trabajar en una empresa con convenio con Crédito Especializado");
    this.requisitos.push("Ine/pasaporte vigente");
    this.requisitos.push("Comprobante de domicilio");
    this.requisitos.push("Recibos de nómina");

    this.condiciones.push("Plazo: hasta 24 meses");
    this.condiciones.push("Tasa fija de 48% anual");
    this.condiciones.push("Sin comisiones");
    this.condiciones.push("Monto de hasta 150 mil pesos.");
  }
  getTipo2() {
    this.requisitos.push("Ine/pasaporte vigente");
    this.requisitos.push("Comprobante de domicilio");
    this.requisitos.push("Estados de cuenta bancarios");
    this.requisitos.push("Aval");

    this.condiciones.push("Plazo: hasta 48 meses");
    this.condiciones.push("Tasa fija");
    this.condiciones.push("GPS");
  }
  getTipo3() {
    this.requisitos.push("Identificación del representante legal");
    this.requisitos.push("Acta constitutiva y poderes");
    this.requisitos.push("Constancia de situación fiscal");
    this.requisitos.push("Comprobantes de ingresos (estados de cuenta, estados financieros, constancia de situación fiscal, etc)");
    this.requisitos.push("Aval");

    this.condiciones.push("Tasa fija o variable");
    this.condiciones.push("Plazo: hasta 48 meses");
    this.condiciones.push("Monto hasta 10 millones de pesos");
  }

  close() {
    this.dialogRef.closeAll();
  }
}  
