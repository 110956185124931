import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepositoriesService } from './services/repositories.service';
import { RegistroService } from './services/registro/registro.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    RepositoriesService,
    RegistroService
  ]
})
export class CoreModule { }
