import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { DialogComponent } from 'src/app/diaglog/components/dialog.component';
import { DialogModule } from 'src/app/diaglog/dialog.module';


import { BuroEntidadesComponent } from 'src/app/buroEntidades/components/buroEntidades/buroEntidades.component';

import { DerechoArcoComponent } from 'src/app/derechoArco/components/derechoArco/derechoArco.component';
import { UneComponent } from 'src/app/une/components/une/une.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';

import { NgxSpinnerModule } from "ngx-spinner";
import { AlertModule } from './_alert';
import { CotizadorComponent } from './cotizador/cotizador.component';
import { CrearPDFComponent } from './crearPDF/crearPDF.component';
import { CotizadorSimuladorComponent } from './cotizador-simulador/cotizador-simulador.component';
import { CrearPDFSimuladorComponent } from './crear-pdfsimulador/crear-pdfsimulador.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent, DialogComponent, BuroEntidadesComponent, DerechoArcoComponent, UneComponent, CotizadorComponent, CrearPDFComponent, CotizadorSimuladorComponent, CrearPDFSimuladorComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule, MatDialogModule, CoreModule, ReactiveFormsModule,
    FormsModule, BrowserAnimationsModule, NgxSpinnerModule, AlertModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'buroEntidades', component: BuroEntidadesComponent },
      { path: 'derechoArco', component: DerechoArcoComponent },
      { path: 'une', component: UneComponent },
      { path: 'cotizador', component: CotizadorComponent },
      { path: 'crearPDF', component: CrearPDFComponent },
      { path: 'cotizador-simulador', component: CotizadorSimuladorComponent },
      { path: 'crear-pdfsimulador', component: CrearPDFSimuladorComponent },
    ])
  ],
  providers: [],
  entryComponents: [DialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
