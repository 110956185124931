import { Component, OnInit, AfterViewInit } from '@angular/core';
import Swiper from 'swiper';

@Component({
  selector: 'app-bases',
  templateUrl: './buroEntidades.component.html',
  styleUrls: ['./buroEntidades.component.scss']
})
export class BuroEntidadesComponent implements OnInit, AfterViewInit {
  mySwiper: Swiper;
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.mySwiper = new Swiper('.swiper-container');
  }
}
