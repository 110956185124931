import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Alert, AlertService } from '../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import swal from 'sweetalert2'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CorreoResponse, RegistroService } from '../core/services/registro/registro.service';
import { Router } from '@angular/router';
import { Hero } from '../../hero';
import { CrearPDFService } from '../core/services/crearPDFservice/crear-pdf.service.service';

//import { CrearPDFComponent } from '../crearPDF/crearPDF.component';
//import * as html2pdf from '../../../../wwwroot/js/ html2pdf.bunle.min.js';

var ValorAutoLocal, EngancheLocal, ValorAutoConForm, EngancheForm, MontoAPrestarForm = '';
var MontoAPrestarLocal = 0;
var TasaAnual = 0;
@Component({
  selector: 'app-cotizador',
  templateUrl: './cotizador.component.html',
  styleUrls: ['./cotizador.component.css']
})
export class CotizadorComponent implements OnInit {
  //formulario
  formularioContacto: FormGroup;
  //variables
  ValorAuto: number = 0;
  Enganche: number = 0;
  EngancheMinimo: number = 0;


  MontoAPrestar: number = 0;
  EngancheValido: boolean = false;
  MontoAPrestarValido: boolean = false;
  EstadoSel: string;
  TipoAuto: string;
  CondicionAuto: string;
  AnioAuto: string;
  MarcaAuto: string;
  PlazoPago: string;
  PlazoPagoNum: number;
  Periodo: string;
  ModeloAuto: string;
  html2pdf: any;
  Mensualidad: number = 0;
  Telefono: string;
  CP: string;
  terminos: string;
  NoPagosAlAnio = 0;
  NoDeAnioss = 0;
  //VALORES ACCESO RAPIDO
  NoPagosAlAnioMes = 12;
  NoPagosAlAnioQuincenas = 26;
  NoPagosAlAnioSemanas = 52;
  PorcentajeEngancheMinimoAuto = 0.2;
  /*  PorcentajeEngancheMinimoMoto = 0.3;*/ //Antes era el 30% ahora el 0%
  PorcentajeEngancheMinimoMoto = 0;
  TasaInteresAnualAuto: number = 0.16;
  TasaInteresAnualMoto: number = 0.45;
  ComisionPorApertura: number = 0.03;



  constructor(
    private formBuilder: FormBuilder,
    protected alertService: AlertService,
    private SpinnerService: NgxSpinnerService,
    private insR: RegistroService,
    private router: Router,
    private CrearPDFServ: CrearPDFService
    //private objPDF: CrearPDFComponent
  ) { this.buildForm(); }

  buildForm() {
    this.formularioContacto = this.formBuilder.group({
      InputNombre: ['', [Validators.required]],
      InputTelefono: ['', [Validators.required]],
      InputCorreo: ['', [Validators.required]],
      InputCP: ['', [Validators.required]],
      ComboEstado: ['', [Validators.required]],
      ComboTipoAuto: ['', [Validators.required]],
      ComboCondicionAuto: ['', [Validators.required]],
      ComboAnio: ['', [Validators.required]],
      ComboMarca: ['', [Validators.required]],
      InputModelo: ['', [Validators.required]],
      InputMonto: ['', [Validators.required]],
      InputEnganche: ['', [Validators.required]],
      ComboPlazo: ['', [Validators.required]],
      ComboPlazoPeriodo: ['', [Validators.required]],
      NoPagosAlAnio: ['', [Validators.required]],
      NoDeAnios: ['', [Validators.required]],
      InputSaldoFinanciado: ['', [Validators.required]],
      InputSaldoFinanciadoConComision: ['', [Validators.required]],
      terminos: ['', [Validators.required]]
    });
  }

  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit() {
    this.loadScript('assets/plugins/sweetalert/sweetalert-dev.js');
    document.getElementById("InputNombre").focus();
    // Si hay un valor por defecto seleccionado, actualizar el valor en el formulario
    const comboPlazoPeriodo = document.getElementById('ComboPlazoPeriodo') as HTMLSelectElement;
    if (comboPlazoPeriodo) {
      comboPlazoPeriodo.value = '1';
      this.formularioContacto.get('ComboPlazoPeriodo').setValue('1');
    }
  }


  //Eventos OnChange de Combos
  SeleccionarEstado(combo) {
    this.EstadoSel = combo;
    //this.funcionEstaticaCalculo();
  }

  SeleccionarTipoAuto(combo) {
    combo = combo.toLowerCase();
    if (combo == "m" || combo == "moto") {
      this.TipoAuto = "moto";
    }
    if (combo == "a" || combo == "auto") {
      this.TipoAuto = "auto";
    }

    //if (this.EngancheMinimo) {
    //  const form = this.formularioContacto.value;
    //  this.calcularEngancheMinimo(form.InputMonto);
    //}
  }

  SeleccionarCondicionAuto(combo) {
    if (combo == "N")
      this.CondicionAuto = "Nuevo";
    else
      this.CondicionAuto = "SemiNuevo";
  }

  SeleccionarAnioAuto(combo) {
    this.AnioAuto = combo;
  }
  SeleccionarMarcaAuto(combo) {
    this.MarcaAuto = combo;
  }



  ValidarMontos() {

    const value = this.formularioContacto.value;
    //Se hace esto para reasignar al formulario enviado los valores de los inputs enmascarados
    this.Telefono = (<HTMLInputElement>document.getElementById("InputTelefono")).value;
    value.InputTelefono = this.Telefono;
    this.CP = (<HTMLInputElement>document.getElementById("InputCP")).value;
    value.InputCP = this.CP;


    this.SpinnerService.show();

    if (value.InputNombre == "") {
      //alert("Hay que capturar el Nombre ");
      this.alertService.warn('Todos los campos son obligatorios, por favor escriba su nombre.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("InputNombre").focus();
      return;
    }
    if (value.InputTelefono == "") {
      //alert("Hay que capturar un numero de telefono");
      this.alertService.warn('Todos los campos son obligatorios, Hay que capturar el numero de telefono.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("InputTelefono").focus();
      return;
    }
    if (value.InputCorreo == "") {
      //alert("Hay que capturar un correo");
      this.alertService.warn('Todos los campos son obligatorios, por favor escriba su correo.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("InputCorreo").focus();
      return;
    }
    if (!this.validarEmail(value.InputCorreo)) {
      this.alertService.warn('Formato Incorrecto, Por favor escriba un correo valido.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("InputCorreo").focus();
      return;
    }

    if (value.InputCP == "") {
      //alert("Hay que capturar el Codigo Postal ");
      this.alertService.warn('Todos los campos son obligatorios, por favor escriba su código postal.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("InputCP").focus();
      return;
    }
    //Comboboxes
    if (!this.EstadoSel) {
      //alert("Hay que seleccionar un Estado");
      this.alertService.warn('Todos los campos son obligatorios, por favor seleccione un estado.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("ComboEstado").focus();
      return;
    }
    if (!this.TipoAuto) {
      this.alertService.warn('Todos los campos son obligatorios, por favor seleccione el tipo de vehiculo.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("ComboTipoAuto").focus();
      return;
    }
    if (!this.CondicionAuto) {
      this.alertService.warn('Todos los campos son obligatorios, por favor seleccione la condición del vehiculo.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("ComboCondicionAuto").focus();
      return;
    }
    if (!this.AnioAuto) {
      //alert("Hay que seleccionar el año");
      this.alertService.warn('Todos los campos son obligatorios, por favor seleccione el año del vehiculo.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("ComboAnio").focus();
      return;
    }
    if (!this.MarcaAuto) {
      //alert("Hay que seleccionar la marca del vehiculo");
      this.alertService.warn('Todos los campos son obligatorios, por favor seleccione la marca del vehiculo.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("ComboMarca").focus();
      return;
    }
    if (!this.PlazoPago) {
      //alert("Hay que seleccionar la marca del vehiculo");
      this.alertService.warn('Todos los campos son obligatorios, por favor seleccione el plazo.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("ComboPlazo").focus();
      return;
    }
    if (!this.Periodo) {
      //alert("Hay que seleccionar la marca del vehiculo");
      this.alertService.warn('Todos los campos son obligatorios, por favor seleccione el periodo.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("ComboPlazoPeriodo").focus();
      return;
    }

    if ((<HTMLInputElement>document.getElementById("InputModelo")).value == "") {
      //alert("Hay que capturar el modelo del vehiculo ");
      this.alertService.warn('Todos los campos son obligatorios, por favor seleccione el modelo del vehiculo.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("InputModelo").focus();
      return;
    }
    else {
      this.ModeloAuto = (<HTMLInputElement>document.getElementById("InputModelo")).value
    }

    if ((<HTMLInputElement>document.getElementById("InputMonto")).value == "") {
      //this.ValorAuto = 0;
      //alert("El valor del auto no puede estar vacio");
      this.alertService.warn('Todos los campos son obligatorios, por favor ponga el precio del vehiculo.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("InputMonto").focus();
      return;
    }
    else {
      //this.ValorAuto = parseFloat((<HTMLInputElement>document.getElementById("InputMonto")).value);
      ValorAutoLocal = (<HTMLInputElement>document.getElementById("InputMonto")).value;

      var ValorAutoSinSignos = ValorAutoLocal.replace('$', '');
      ValorAutoSinSignos = ValorAutoSinSignos.split(',').join('');
      ValorAutoLocal = ValorAutoSinSignos;
      this.ValorAuto = parseFloat(ValorAutoSinSignos);
    }

    if ((<HTMLInputElement>document.getElementById("InputEnganche")).value == "") {
      //this.Enganche = 0;
      this.alertService.warn('Todos los campos son obligatorios, por favor ponga el enganche.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("InputEnganche").focus();
      return;
    }
    else {
      var x = (<HTMLInputElement>document.getElementById("InputEnganche")).value;
      var EngancheSinSignos = x.replace('$', '');
      EngancheSinSignos = EngancheSinSignos.split(',').join('');
      EngancheLocal = EngancheSinSignos;
      this.Enganche = EngancheLocal;
    }

    this.MontoAPrestar = this.ValorAuto - this.Enganche;
    MontoAPrestarLocal = this.ValorAuto - this.Enganche;
    MontoAPrestarForm = this.formatearSinCentavos(this.MontoAPrestar);

    //VALIDACIONES DE ENGANCHE MINIMO Y FINANCIAMIENTO
    //SI ES AUTO
    if (this.TipoAuto == "auto") {
      this.EngancheMinimo = this.ValorAuto * 0.2;
      //Validación porque el enganche minimo es del 20%.
      if (this.Enganche < this.EngancheMinimo) {
        alert("El enganche minimo es del 20% del valor del auto ($" + this.EngancheMinimo + ").");
        this.EngancheValido = false;
        return;
      }
      else {
        this.EngancheValido = true;
      }
    }
    else {
      //Validación porque el enganche minimo es del 30%.
      //this.EngancheMinimo = this.ValorAuto * 0.3;
      //Ahora lo toma desde la vartiable global para un facil
      this.EngancheMinimo = this.ValorAuto * this.PorcentajeEngancheMinimoMoto;
      if (this.Enganche < this.EngancheMinimo) {
        alert("El enganche minimo es del 30% del valor del auto ($" + this.EngancheMinimo + ").");
        this.EngancheValido = false;
        return;
      }
      else {
        this.EngancheValido = true;
      }
    }


    if (this.TipoAuto == "auto") {
      //Validación de que el MontoAPrestar esté entre 80 mil y 3 millones
      //alert("MontoAprestar min 2" + this.MontoAPrestar);
      if (MontoAPrestarLocal < 80000) {
        alert("El monto mínimo a financiar es de $80,000 y se ha solicitado $" + this.MontoAPrestar + ".");
        //alert("Enganche vale " + this.Enganche + " Enganche Minimo vale " + this.EngancheMinimo + "MontoAPrestar vale:" + this.MontoAPrestar)

        this.MontoAPrestarValido = false;
        return;
      }
      if (this.MontoAPrestar > 3000000) {
        alert("El monto máximo a financiar es de 3 millones y se ha solicitado $" + this.MontoAPrestar + ". tipo auto mayor" + this.TipoAuto);
        //alert("Enganche vale " + this.Enganche + " Enganche Minimo vale " + this.EngancheMinimo + "MontoAPrestar vale:" + this.MontoAPrestar)

        this.MontoAPrestarValido = false;
        return;
      }
      this.MontoAPrestarValido = true;

    }
    else {
      //Validación de que el MontoAPrestar esté entre 80 mil y 3 millones
      //alert("MontoAprestar min 2" + this.MontoAPrestar);
      if (MontoAPrestarLocal < 10000) {
        alert("El monto mínimo a financiar es de $10,000 y se ha solicitado $" + this.MontoAPrestar + ". Tipo moto:" + this.TipoAuto);
        //alert("Enganche vale " + this.Enganche + " Enganche Minimo vale " + this.EngancheMinimo + "MontoAPrestar vale:" + this.MontoAPrestar)

        this.MontoAPrestarValido = false;
        return;
      }
      if (this.MontoAPrestar > 3000000) {
        alert("El monto máximo a financiar es de 3 millones y se ha solicitado $" + this.MontoAPrestar + ". tipo moto mayor" + this.TipoAuto);
        //alert("Enganche vale " + this.Enganche + " Enganche Minimo vale " + this.EngancheMinimo + "MontoAPrestar vale:" + this.MontoAPrestar)

        this.MontoAPrestarValido = false;
        return;
      }
      this.MontoAPrestarValido = true;
    }


    if (this.EngancheValido && this.MontoAPrestarValido) {
      return true;
    }

  }

  calcularEngancheMinimo(input) {
    if (!this.TipoAuto) {
      this.alertService.warn('Seleccione el tipo de vehiculo.', { autoClose: true });
      this.SpinnerService.hide();
      document.getElementById("ComboTipoAuto").focus();
      return;
    }

    // Se limpia inputMonto de $ y ,
    var ValorAutoSinSignos = input.replace('$', '');
    ValorAutoSinSignos = ValorAutoSinSignos.split(',').join('');
    this.ValorAuto = ValorAutoSinSignos;
    ValorAutoLocal = ValorAutoSinSignos;

    // Si es Auto el enganche es del 20% y si es Moto es del 20%
    this.EngancheMinimo = this.ValorAuto * 0.2;
    if (this.TipoAuto == "auto") {
      this.EngancheMinimo = this.ValorAuto * 0.2;
    } else {
      this.EngancheMinimo = this.ValorAuto * 0.2;
    }

    ValorAutoConForm = this.formatearSinCentavos(this.ValorAuto);
    EngancheForm = this.formatearSinCentavos(this.EngancheMinimo);

    // Actualizar el valor del formulario explícitamente
    this.formularioContacto.get('InputMonto').setValue(ValorAutoConForm);
    this.formularioContacto.get('InputEnganche').setValue(EngancheForm);

    this.Enganche = parseFloat((<HTMLInputElement>document.getElementById("InputEnganche")).value);
    this.MontoAPrestar = ValorAutoLocal - this.EngancheMinimo;
    MontoAPrestarLocal = ValorAutoLocal - this.EngancheMinimo;

    //this.calculoMensualidaOnBlur2();
    this.funcionEstaticaCalculo()
  }




  SeleccionarPlazo(combo) {
    // Selecciono por default el tipo de pago mensual
    const comboPlazoPeriodo = document.getElementById('ComboPlazoPeriodo') as HTMLSelectElement;

    if (comboPlazoPeriodo) {
      comboPlazoPeriodo.value = '1';
      this.formularioContacto.get('ComboPlazoPeriodo').setValue('1');

      // Emitir el evento de cambio manualmente si es necesario
      const event = new Event('change', { bubbles: true });
      comboPlazoPeriodo.dispatchEvent(event);
    }

    this.PlazoPago = combo + ' meses';
    this.PlazoPagoNum = combo;
    this.MontoAPrestar = ValorAutoLocal - this.EngancheMinimo;
    MontoAPrestarLocal = ValorAutoLocal - this.EngancheMinimo;

    // Llamar a la función que realiza el cálculo
    this.funcionEstaticaCalculo();
  }


  SeleccionarPeriodo(select) {
    switch (select) {
      case "1":
        this.Periodo = "mensualidades";
        this.NoPagosAlAnio = this.NoPagosAlAnioMes;
        this.formularioContacto.get('NoPagosAlAnio').setValue(this.NoPagosAlAnioMes);
        break;
      case "2":
        this.Periodo = "quincenas";
        this.NoPagosAlAnio = this.NoPagosAlAnioQuincenas;
        this.formularioContacto.get('NoPagosAlAnio').setValue(this.NoPagosAlAnioQuincenas);
        break;
      case "3":
        this.Periodo = "semanas";
        this.NoPagosAlAnio = this.NoPagosAlAnioSemanas;
        this.formularioContacto.get('NoPagosAlAnio').setValue(this.NoPagosAlAnioSemanas);
        break;
    }
    // Actualizar el valor del formulario explícitamente
    this.formularioContacto.get('ComboPlazoPeriodo').setValue(select);

    //this.calculoMensualidaOnBlur2();
    this.funcionEstaticaCalculo();
  }

  CalculaMensualidad(input) {

    var EngancheSinSignos = input.replace('$', '');
    EngancheSinSignos = EngancheSinSignos.split(',').join('');
    this.EngancheMinimo = EngancheSinSignos;
    EngancheLocal = EngancheSinSignos;

    var EngancheConForm = this.formatearSinCentavos(this.EngancheMinimo);
    (<HTMLInputElement>document.getElementById("InputEnganche")).value = EngancheConForm;

    this.MontoAPrestar = ValorAutoLocal - this.EngancheMinimo;
    MontoAPrestarLocal = ValorAutoLocal - this.EngancheMinimo;

    //this.calculoMensualidaOnBlur2();
    this.funcionEstaticaCalculo();
  }



  //TODO: se puede cambiar NoPagosAnuales por NoPagosAlAnio quitando el case (probar)
  calculoMensualidaOnBlur2() {
    // SI EL FORMULARIO ES VÁLIDO HACE EL CÁLCULO, SI NO, NO
    if (this.validarCamposVacios()) {
      const form = this.formularioContacto.value;
      if (this.PlazoPagoNum) {
        let mens = 0;
        // Monto
        let montoFinanciado = this.MontoAPrestar;
        console.log("Monto Financiado 1: ", montoFinanciado);
        form.InputSaldoFinanciado = montoFinanciado;

        // Comisión por apertura
        let ComisionPorApertura = montoFinanciado * this.ComisionPorApertura;
        ComisionPorApertura += ComisionPorApertura * 0.16; // Añadir IVA
        console.log("Comisión por Apertura 1: ", ComisionPorApertura);

        // Monto financiado total
        montoFinanciado += ComisionPorApertura;

        // Años
        const anios = Number(this.PlazoPagoNum) / 12;
        this.NoDeAnioss = anios;
        console.log("Plazo en años: ", anios);

        // Pagos Anuales
        let NoPagosAnuales = 12;

        // Tasa de Interés Anual
        let TasaAnual;
        if (this.TipoAuto === "auto") {
          TasaAnual = this.TasaInteresAnualAuto;
        } else {
          TasaAnual = this.TasaInteresAnualMoto;

          // Ajustar NoPagosAnuales según el periodo seleccionado si es moto
          const periodoSeleccionado = this.formularioContacto.get('ComboPlazoPeriodo').value;
          console.log("Periodo seleccionado: ", periodoSeleccionado);
          switch (periodoSeleccionado) {
            case '1': // Mensual
              NoPagosAnuales = this.NoPagosAlAnioMes;
              break;
            case '2': // Quincenal
              NoPagosAnuales = this.NoPagosAlAnioQuincenas;
              break;
            case '3': // Semanal
              NoPagosAnuales = this.NoPagosAlAnioSemanas;
              break;
            default:
              console.log("Período no reconocido, usando pagos mensuales por defecto.");
              NoPagosAnuales = 12;
          }
        }

        // Tipo de interés fraccionado (del periodo)
        const im = TasaAnual / NoPagosAnuales / 100; // Convertir porcentaje a decimal
        const im2 = Math.pow((1 + im), -(NoPagosAnuales * anios));

        // Cuota Cap. + Int.
        mens = (montoFinanciado * im) / (1 - im2);
        console.log("Mensualidad: ", mens);

        const EngancheForm = this.formatear(mens);
        console.log("Mensualidad Formateada: ", EngancheForm);
        document.getElementById("labelMensualidad").innerText = "Tu pago sería de " + EngancheForm;
      }
    } else {
      document.getElementById("labelMensualidad").innerText = " ";
    }
  }

  validarCamposVacios() {
    const form = this.formularioContacto.value;
    if (!form.ComboTipoAuto) {
      console.log("falló Tipo auto");
      return false;
    }
    if (!form.ComboCondicionAuto) {
      console.log("falló Condicion auto");
      return false;
    }
    if (!form.InputMonto) {
      console.log("Fallo input monto")
      return false;
    }
    //if (!form.InputEnganche) {
    //  const InputLocalEnganche = this.formularioContacto.get('InputEnganche').value;
    //  console.log("InputLocalEnganche", InputLocalEnganche);
    //  console.log(form)
    //  console.log("falló input enganche")
    //  return false;
    //}
    if (!form.ComboPlazo) {
      console.log("falló select plazo")
      return false;
    }
    else {
      return true;
    }

  }





  formatear(number) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
  }

  formatearSinCentavos(number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency', currency: 'USD', maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }).format(number);
  }

  //Esto es donde se manda al PDF
  CrearCotizacion() {


    const form = this.formularioContacto.value;
    if (!form.terminos) {
      this.alertService.warn('Favor de aceptar Aviso de privacidad', { autoClose: true });
      return;
    }
    else {
      //Si esta lleno el formulario enviará el email
      if (this.ValidarMontos()) {
        this.SpinnerService.hide();

        form.NoDeAnios = this.NoDeAnioss;

        console.log("cform enviado", form);
        //window.location.href = "/crearPDF";
        form.InputMonto = ValorAutoLocal;
        form.InputEnganche = EngancheLocal;
        //form.InputSaldoFinanciado = MontoAPrestarLocal;
        //alert("formSaldoFin" + MontoAPrestarLocal)
        this.CrearPDFServ.disparadorDeFavoritos.emit({
          data: form
        });
        console.log("form", form);
        //GUARDAMOS LOS DATOS DEL FORMULARIO PARA LUEGO RECUPERARLOS EN crearPDF.component.ts CON EL CODIGO '  let JSONTex = localStorage.getItem('cotizacion');'
        localStorage.setItem('cotizacion', JSON.stringify(form));

        //this.router.navigateByUrl('/crearPDF');
        this.router.navigate([]).then(result => { window.open('/crearPDF', '_blank'); });
      }
    }

  }


  EnviarCorreo() {
    //Si esta lleno el formulario enviará el email
    //this.SpinnerService.show();
    const form = this.formularioContacto.value;
    if (!form.terminos) {
      this.alertService.warn('Favor de aceptar Aviso de privacidad', { autoClose: true });
      return;
    }
    else {
      if (this.ValidarMontos()) {
        //alert("es valido y enviaré correo");
        const form = this.formularioContacto.value;
        //window.location.href = "/crearPDF";
        //form.InputMonto = ValorAutoConForm;
        //form.InputEnganche = EngancheLocal;

        //debugger;
        console.log('año:' + this.AnioAuto)
        this.SpinnerService.show();


        this.insR.EnviarCorreoCotizacion(form.InputNombre, form.InputTelefono, form.InputCorreo, this.EstadoSel, form.InputCP, this.TipoAuto, this.AnioAuto, this.MarcaAuto, this.ModeloAuto, ValorAutoConForm, EngancheForm, MontoAPrestarForm, this.PlazoPago).subscribe(data => {
          console.log('la data contacto: ', data)
          if (data['estatus'] == 200) {
            alert('Se ha enviado un correo a un asesor, pronto se contactarán contigo');

            console.log("estatus 200-OK");
            this.SpinnerService.hide();
            this.alertService.success('Correo enviado correctamente', { autoClose: true });
            this.formularioContacto.reset();
          }
          else {
            console.log("fue error");
            this.alertService.error('Se registro un error al guardar correo', { autoClose: true });
            this.SpinnerService.hide();
            return;
          }
        });


      }
      else {
        //this.alertService.warn('Todos los campos son obligatorios, Favor de capturar datos.', { autoClose: true });
        //this.SpinnerService.hide();
      }
    }
  }


  validarEmail(valor) {
    var regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(valor) ? true : false;
  }

  funcionEstaticaCalculo() {
    // Obtener los valores del formulario
    const precioAutoValor = this.formularioContacto.get('InputMonto').value;
    const engancheValor = this.formularioContacto.get('InputEnganche').value;
    const plazoValor = this.formularioContacto.get('ComboPlazo').value;
    const periodoValor = this.formularioContacto.get('ComboPlazoPeriodo').value;
    const tipoVehiculoValor = this.formularioContacto.get('ComboTipoAuto').value;

    this.NoDeAnioss = plazoValor/12;

    console.log('precioAutoValor:', precioAutoValor);
    console.log('engancheValor:', engancheValor);
    console.log('plazoValor:', plazoValor);
    console.log('periodoValor:', periodoValor);
    console.log('tipoVehiculoValor:', tipoVehiculoValor);

    // Validar si los valores son válidos
    if (!precioAutoValor) {
      document.getElementById("labelMensualidad").innerText = " ";
      console.log('El campo "Precio del vehículo" está vacío o no es válido.');
      return;
    }
    if (!engancheValor) {
      document.getElementById("labelMensualidad").innerText = " ";
      console.log('El campo "Enganche" está vacío o no es válido.');
      return;
    }
    if (!plazoValor) {
      document.getElementById("labelMensualidad").innerText = " ";
      console.log('El campo "Plazo" está vacío o no es válido.');
      return;
    }
    if (!periodoValor) {
      document.getElementById("labelMensualidad").innerText = " ";
      console.log('El campo "Periodo" está vacío o no es válido.');
      return;
    }
    if (!tipoVehiculoValor) {
      document.getElementById("labelMensualidad").innerText = " ";
      console.log('El campo "Tipo de vehículo" está vacío o no es válido.');
      return;
    }

    // Limpiar y convertir los valores a números
    const precioAuto: number = this.limpiarValor(precioAutoValor);
    const enganche: number = this.limpiarValor(engancheValor);
    const numeroMeses: number = +plazoValor;
    const periodoPago: number = +periodoValor;
    const tipoVehiculo: string = tipoVehiculoValor;

    console.log('precioAuto:', precioAuto);
    console.log('enganche:', enganche);
    console.log('numeroMeses:', numeroMeses);
    console.log('periodoPago:', periodoPago);
    console.log('tipoVehiculo:', tipoVehiculo);

    // Monto financiado (precio del auto menos el enganche)
    const montoFinanciado: number = precioAuto - enganche;
    console.log('montoFinanciado:', montoFinanciado);

    // Comisión por apertura (3% del monto financiado)
    const comisionSinIVA: number = montoFinanciado * 0.03;
    console.log('comisionSinIVA:', comisionSinIVA);

    // IVA sobre la comisión (16% de la comisión sin IVA)
    const IVA: number = comisionSinIVA * 0.16;
    console.log('IVA:', IVA);

    // Comisión total (comisión sin IVA más el IVA)
    const comisionTotal: number = comisionSinIVA + IVA;
    console.log('comisionTotal:', comisionTotal);

    // Monto total a financiar (monto financiado más la comisión total)
    const montoTotalFinanciado: number = montoFinanciado + comisionTotal;
    console.log('montoTotalFinanciado:', montoTotalFinanciado);

    // Tasa de interés anual según el tipo de vehículo
    let tasaInteresAnual: number;
    if (tipoVehiculo === 'auto') {
      tasaInteresAnual = this.TasaInteresAnualAuto;
    } else if (tipoVehiculo === 'moto') {
      tasaInteresAnual = this.TasaInteresAnualMoto;
    }
    console.log('tasaInteresAnual:', tasaInteresAnual);

    // Tasa de interés según el periodo de pago y número total de pagos
    let tasaInteresPeriodo: number;
    let numPagosTotales: number;
    if (periodoPago === 1) { // Mensual
      tasaInteresPeriodo = tasaInteresAnual / this.NoPagosAlAnioMes;
      numPagosTotales = numeroMeses;
    } else if (periodoPago === 2) { // Quincenal
      tasaInteresPeriodo = tasaInteresAnual / this.NoPagosAlAnioQuincenas;
      numPagosTotales = numeroMeses * 2;
    } else if (periodoPago === 3) { // Semanal
      tasaInteresPeriodo = tasaInteresAnual / this.NoPagosAlAnioSemanas;
      numPagosTotales = numeroMeses * 4.33;
    }
    console.log('tasaInteresPeriodo:', tasaInteresPeriodo);
    console.log('numPagosTotales:', numPagosTotales);

    // Factor de descuento
    const factorDescuento: number = Math.pow((1 + tasaInteresPeriodo), -numPagosTotales);
    console.log('factorDescuento:', factorDescuento);

    // Mensualidad (con interés compuesto)
    const mensualidad: number = (montoTotalFinanciado * tasaInteresPeriodo) / (1 - factorDescuento);
    console.log('mensualidad:', mensualidad);

    // Imprimir el resultado
    document.getElementById("labelMensualidad").innerText = `Tu pago seria de: $${mensualidad.toFixed(2)} MXN`;
    console.log(`La mensualidad es de aproximadamente: $${mensualidad.toFixed(2)} MXN`);
  }

  limpiarValor(valor: string): number {
    return parseFloat(valor.replace(/[$,]/g, ''));
  }


}


  //calculoMensualidaOnBlur() {
  //  const form = this.formularioContacto.value;
  //  if (this.PlazoPagoNum) {

  //    var mens = 0;
  //    // Monto
  //    var montoFinanciado = this.MontoAPrestar;
  //    //console.log("Monto a prestar: " + co);

  //    form.InputSaldoFinanciado = montoFinanciado;
  //    MontoAPrestarForm = this.formatearSinCentavos(this.MontoAPrestar);

  //    // Años
  //    var anios = Number(this.PlazoPagoNum) / 12;
  //    //console.log("años:" + n);
  //    // Pagos Anuales
  //    var NoPagosAnuales = 12;
  //    // Tasa Interes

  //    //SI ES AUTO LA TASA DE INTERES ES DE 16% Y SI ES MOTO DEL 35%
  //    if (this.TipoAuto == "auto") {
  //      TasaAnual = 0.16;
  //    }
  //    else {
  //      TasaAnual = 0.35;
  //    }
  //    //var TasaInteres = 18.56;
  //    var TasaInteres = (TasaAnual * 1.16) * 100;
  //    // Tipo de interés fraccionado (del periodo)
  //    var im = TasaInteres / NoPagosAnuales / 100;
  //    //console.log("interes fraccionado: " + im);

  //    var im2 = Math.pow((1 + im), -(NoPagosAnuales * anios));
  //    //console.log("interes fraccionado 2: " + im2);

  //    // Cuota Cap. + Int.
  //    mens = (montoFinanciado * im) / (1 - im2);
  //    var EngancheForm = this.formatear(mens);
  //    //console.log("mensualidad: " + EngancheForm);
  //    document.getElementById("labelMensualidad").innerText = "Tu mensualidad sería de " + EngancheForm;
  //    //console.log("Cuota Cap + Int: " + a.toFixed(2));
  //  }
  //}
