import { Injectable } from '@angular/core';
import { RegistroService } from './registro/registro.service';

@Injectable({
  providedIn: 'root'
})
export class RepositoriesService {

  constructor(
    public registroService: RegistroService
  ) { }
}
