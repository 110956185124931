import { Component, OnInit } from '@angular/core';


var InputMeses, InputPago = '';
@Component({
  selector: 'app-crear-pdfsimulador',
  templateUrl: './crear-pdfsimulador.component.html',
  styleUrls: ['./crear-pdfsimulador.component.css']
})
export class CrearPDFSimuladorComponent implements OnInit {
  html2pdf: any;
  constructor() { }

  ngOnInit() {

    setTimeout(() => {
      let JSONTex = localStorage.getItem('cotizacionSimulacion');
      let newObject = JSON.parse(JSONTex);
      console.log("cotizacionSimulacion", newObject)
      this.setearValores(newObject);


      this.crearTablaAmortizacion();
    }, 500)
  }

  setearValores(newObject) {
    InputMeses = newObject["InputMeses"];
    InputPago = newObject["InputPago"];
    //Validacion para volver a calcular el SaldoFinanciado, en caso de que se pierda el valor en el submit...

  }
  crearTablaAmortizacion() {
    //VARIABLES ESTATICAS
    var TIIE = 0.1126;
    var Margen = 0.5;
    var B4 = TIIE + Margen;
    var B5 = 0.16
    var NoVecesEnUnAnio = 12;

    var meses = InputMeses;
    var quincenas = meses * 2;
    var semanas = quincenas * 2;
    var pagoMensual = InputPago;
    var B12 = Number(pagoMensual);

    var L12 = 12;
    var L11 = 24;
    var L10 = 52;


    var StringVacio = '';
    for (let periodo = 1; periodo <= L10; periodo++) {
      var resultadoMensual = B12 * (1 - (1 + B4 * (1 + B5) / L12) ** (-periodo)) / (B4 * (1 + B5) / L12);
      var redondeadoMensual = resultadoMensual.toFixed(2);
      var resultadoQuincenal = B12 * (1 - (1 + B4 * (1 + B5) / L11) ** (-periodo)) / (B4 * (1 + B5) / L11);
      var redondeadoQincenal = resultadoQuincenal.toFixed(2);
      var resultadoSemanal = B12 * (1 - (1 + B4 * (1 + B5) / L10) ** (-periodo)) / (B4 * (1 + B5) / L10);
      var redondeadoSemanal = resultadoSemanal.toFixed(2);

      var tr = document.createElement('tr');
      if (periodo <= L12) {
        tr.innerHTML = '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + periodo + '</td>' +
          '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + redondeadoSemanal + '</td>' +
          '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + redondeadoQincenal + '</td>' +
          '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + redondeadoMensual + '</td>';
        var tbody = document.getElementById('tablaAmortizacion').getElementsByTagName('tbody')[0];
        tbody.appendChild(tr);
      }
      else
      {
        if (periodo <= L11) {
          tr.innerHTML = '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + periodo + '</td>' +
            '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + redondeadoSemanal + '</td>' +
            '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + redondeadoQincenal + '</td>' +
            '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + StringVacio + '</td>';
          var tbody = document.getElementById('tablaAmortizacion').getElementsByTagName('tbody')[0];
          tbody.appendChild(tr);
        }
        else {
          if (periodo <= 48) {
            if (periodo <= 40) {
              tr.innerHTML = '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + periodo + '</td>' +
                '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + redondeadoSemanal + '</td>' +
                '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + StringVacio + '</td>' +
                '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + StringVacio + '</td>';
              var tbody = document.getElementById('tablaAmortizacion').getElementsByTagName('tbody')[0];
              tbody.appendChild(tr);

            }
            else {
              document.getElementById("divTabla2").hidden = false;
              tr.innerHTML = '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + periodo + '</td>' +
                '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + redondeadoSemanal + '</td>' +
                '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + StringVacio + '</td>' +
                '<td style="border: 0.1px solid black; border-collapse: collapse; font-size: 12px; margin - top: 0.5em; margin-bottom: 0em;">' + StringVacio + '</td>';
              var tbody = document.getElementById('tablaAmortizacion2').getElementsByTagName('tbody')[0];
              tbody.appendChild(tr);
            }
           
          }

        }
      }
      

      //var tbody = document.getElementById('tablaAmortizacion').getElementsByTagName('tbody')[0];
      //tbody.appendChild(tr);
      console.log("ResMensual:", redondeadoMensual);
    }
    var br = document.createElement('br');
    var br2 = document.createElement('br');
    var elementoTabla = document.getElementById('tablaAmortizacion');
    elementoTabla.after(br);
    elementoTabla.after(br2);
  }


  download() {
    var element = document.getElementById('table');
    var opt = {
      margin: 1,
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    // New Promise-based usage:
    this.html2pdf().from(element).set(opt).save();
  }


}
