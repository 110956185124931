import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { HttpEventType, HttpClient } from '@angular/common/http';
import { RegistroResponse, RegistroService } from 'src/app/core/services/registro/registro.service';
import { RepositoriesService } from 'src/app/core/services/repositories.service';
import { Validators, FormGroup, FormBuilder, FormControl, ReactiveFormsModule   } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/diaglog/components/dialog.component';
import { AlertService } from '../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import swal from 'sweetalert2'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

interface Productos {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, AfterViewInit{
  bandera: number = 0;
  visible: boolean = false;
  visibleAu: boolean = false;
  visibleEm: boolean = false;
  form: FormGroup;
  formularioContacto:FormGroup;
  selectedValue: string;

  nombreContacto: string;
  emailConctaco: string;
  telefonoContacto: string;
  mensajeContacto: string;

  nombre : string;
  apellido: string;
  telefono: string;
  asunto: string;
  mensaje: string;
  archivo: string;
  rutaArchvo: string;
  extArchivo: string;
  area: string;
  terminos: string;
terminosCnd: String;

  public progress: number;
  public message: string;

  @Output() public onUploadFinished = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private repositoriesService: RepositoriesService,
    private insR: RegistroService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    protected alertService: AlertService,
    private SpinnerService: NgxSpinnerService
  ) { this.buildForm(); }

  private buildForm() {
    this.form = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      apellido: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      asunto: ['', [Validators.required]],
      mensaje: ['', [Validators.required]],
      archivo: ['', [Validators.required]],
      rutaArchivo: ['', [Validators.required]],
      extArchivo: ['', [Validators.required]],
      area: ['', [Validators.required]],
      terminosCnd: ['', [Validators.required]]
    });

    this.formularioContacto = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      email: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      mensaje: ['', [Validators.required]],
      asunto: ['', [Validators.required]],
      terminos: ['', [Validators.required]]
    });
  }

    ngAfterViewInit(): void {
      this.bandera = 1;
  }

  /*Elementos de Productos en Form Envíanos tu mensaje*/
  Productos: Productos[] = [
    { value: '1', viewValue: 'Crédito Nómina' },
    { value: '2', viewValue: 'Crédito Automotriz' },
    //{ value: '3', viewValue: 'Crédito empresarial' },
    //{ value: '4', viewValue: 'Seguro de gastos médicos primarios' },
    //{ value: '5', viewValue: 'Seguro de autos' }
    { value: '3', viewValue: 'Crédito Moto' },
    { value: '4', viewValue: 'Crédito Automotriz Especializado' },
    { value: '5', viewValue: 'Crédito Pyme' },
    { value: '6', viewValue: 'Seguro de autos' },
    { value: '7', viewValue: 'Tarjeta de crédito Clikstore' }
  ];

  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  ngOnInit(): void {
    this.loadScript('assets/js/bolsatrabajo.js');
    this.loadScript('assets/js/subirdocumento.js');
    this.loadScript('assets/plugins/sweetalert/sweetalert-dev.js');
    this.bandera = 1;    
  }
  
  /*AceptarTerminos(evt){
    if (this.terminos == "0")
      this.terminos = "1";
    else
      this.terminos = "0";
  }*/
  mostrarReq() {
    this.visible = true;
  }
  ocultarReq() {
    this.visible = false;

  }

  mostrarReqAu() {
    this.visibleAu = true;
  }
  ocultarReqAu() {
    this.visibleAu = false;

  }
  mostrarReqEm() {
    this.visibleEm = true;
  }
  ocultarReqEm() {
    this.visibleEm = false;

  }
  
  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    this.http.post('https://localhost:5001/api/upload', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      });
  }

  openDialog(data) {
    //alert("Entre al dialog");
    //debugger;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      tipo: data
    };
    this.dialog.open(DialogComponent, dialogConfig);
  }

  validar() {
    const value = this.form.value;
    debugger;
    let fileToUpload = <File>value.fileUpload[0].files[0];
    console.log('Entre a registrar')
    this.insR.Registro(value.nombre, value.asunto, value.telefono, value.area, fileToUpload,
      value.archivo, value.extArchivo).subscribe(data => {

        console.log('la data: ', data)
        if (data['estatus'] == 200) {
          this.alertService.success(data.mensaje, { autoClose: false });
        }
        else {
          alert('Se registro un error al guardar correo');
        }
      });
  }
  /*SECCION CONTACTANOS: Form: Envianos tu mensaje*/
  enviarCorreo() {
    const value = this.formularioContacto.value;
    //debugger;
    console.log('Entre a registrar')
    this.SpinnerService.show();
    if (value.terminos) {
      if (value.nombre == "" || value.telefono == "" || value.email == "" || value.asunto.viewValue == "" || value.asunto.viewValue == "Selecciona producto") {
        this.alertService.warn('Todos los campos son obligatorios, Favor de capturar datos.', { autoClose: true });
        this.SpinnerService.hide();
        return;
      }
      else { 
        this.insR.EnviarCorreo(value.nombre, value.telefono, value.email, value.mensaje, value.asunto.viewValue).subscribe(data => {
        console.log('la data contacto: ', data)
          if (data['estatus'] == 200) {
            //alert('Correo guardado correctamente');
            this.SpinnerService.hide();
            this.alertService.success('Correo enviado correctamente', { autoClose: true });
            this.formularioContacto.reset();
          }
          else {
            this.alertService.error('Se registro un error al guardar correo', { autoClose: true });
            this.SpinnerService.hide();
            return;
          }
          });
      }
    }
    else {
     this.alertService.warn('Favor de aceptar aviso de privacidad', { autoClose: true });
      this.SpinnerService.hide();
      return;
    }
  }


}
